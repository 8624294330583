<template>
  <main>
    <v-main>
      <v-container fill-height fluid>
        <!-- On fait un layout avec une card pour faire un truc joli -->
        <v-layout align-center justify-center>
          <v-flex class="login-form">
            <div style="flex-direction: column; text-align: center" v-if="isValidLicense" class="ma-5 d-flex caption">
              {{ $t('license.connectedTo') }}
              {{ branchName }}
              <v-btn color="warning" outlined prepend-icon="edit" small @click="removeLicense">
                <v-icon left>edit</v-icon>
                {{ $t('license.change') }}
              </v-btn>
            </div>
            <div v-else class="ma-5 text-center">
              <h1>YodaCloud Overview</h1>
            </div>
            <v-card class="elevation-2">
              <v-card-text>
                <v-form v-if="!isValidLicense" ref="licenseForm" @submit="validateLicense">
                  <p v-html="$t('login.licenseExplanation1')"/>
                  <p v-html="$t('login.licenseExplanation2')"/>
                  <!-- Champ de saisie de la licence -->
                  <v-text-field v-model="input.license"
                                :label="$t('license.placeholder')"
                                clear-icon="delete"
                                clearable
                                name="licence"
                                outlined
                                prepend-icon="cloud"
                                required
                                @click:clear="removeLicense">
                  </v-text-field>

                  <qrcode-stream v-if="showQRScanner" @decode="onDecode" @init="onInit"/>

                  <!-- Bouton pour déclencher la validation de la licence -->
<!--                    <v-btn v-if="!isValidLicense" :title="$t('login.scan')" @click="showQRScanner = !showQRScanner">-->
<!--                      <svg style="width:24px;height:24px" viewBox="0 0 24 24">-->
<!--                        <path-->
<!--                          d="M3,11H5V13H3V11M11,5H13V9H11V5M9,11H13V15H11V13H9V11M15,11H17V13H19V11H21V13H19V15H21V19H19V21H17V19H13V21H11V17H15V15H17V13H15V11M19,19V15H17V19H19M15,3H21V9H15V3M17,5V7H19V5H17M3,3H9V9H3V3M5,5V7H7V5H5M3,15H9V21H3V15M5,17V19H7V17H5Z"-->
<!--                          fill="#000000"/>-->
<!--                      </svg>-->
<!--                    </v-btn>-->
                    <v-btn block v-if="!isValidLicense" :loading="validatingLicense" color="success" @click="validateLicense">
                      {{ $t('license.validateBtn') }}
                    </v-btn>
                  <!--Le formulaire de login ne s'affiche qu'une fois la licence validée-->
                </v-form>
                <v-form ref="loginForm" @submit="tryLogin">
                  <div v-if="isValidLicense">
                    <h2>Se connecter</h2>
                    <v-card-text>{{ $t('login.credentialsText') }}</v-card-text>
                    <!-- Champ de saisie du login -->
                    <v-text-field
                      tabindex="1"
                      v-model="input.username"
                      :label="$t('login.usernamePlaceholder')"
                      autofocus
                      clear-icon="delete"
                      clearable
                      name="login"
                      prepend-icon="person"
                      required>
                    </v-text-field>
                    <!-- Champ de saisie du mot de passe -->
                    <v-text-field
                      tabindex="2"
                      v-model="input.password"
                      :label="$t('login.passwordPlaceholder')"
                      clear-icon="delete"
                      clearable
                      name="password"
                      prepend-icon="lock"
                      required
                      type="password">
                    </v-text-field>
                    <!-- Bouton pour déclencher le login -->
                    <v-btn tabindex="3" block :loading="validatingLogin" color="success" @click="tryLogin">{{ $t('login.loginBtn') }}
                    </v-btn>
                  </div>
                </v-form>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
      <!-- Pour afficher des messages -->
      <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout" top>{{ snackbar.text }}
        <v-btn text @click="snackbar.show = false">{{ $t('close') }}</v-btn>
      </v-snackbar>
    </v-main>
  </main>
</template>

<script>
import {QrcodeStream} from 'vue-qrcode-reader'

export default {
  components: {QrcodeStream},
  name: 'OverviewLoginView',
  data() {
    return {
      // indique si la licence est valide afin d'afficher le bloc user password
      isValidLicense: false,
      branchName: null,
      // contenu du formulaire
      input: {
        license: '',
        username: '',
        password: ''
      },
      // pour afficher des messagees
      snackbar: {
        text: '',
        show: false,
        color: '',
        timeout: 5000
      },
      // Pour le spinner du bouton de login
      validatingLogin: false,
      // Pour le spinner du bouton de licence
      validatingLicense: false,
      showQRScanner: false
    }
  },
  filters: {
    mask(toMask) {
      return toMask.substring(0, 7) + "******" + toMask.slice(-7)
    }
  },
  mounted() {
    this.trySavedOrGivenLicence();
  },
  methods: {
    trySavedOrGivenLicence() {
      let urlSearchParams = new URLSearchParams(location.search)
      let license = urlSearchParams.get('license')
      if (license) {
        this.input.license = license
        this.validateLicense()
      } else if (localStorage.license) {
        this.input.license = localStorage.license
        this.validateLicense()
      }
    },
    // Déclenché au clic sur le bouton "valider la licence" afin de vérifier la licence et d'afficher le formulaire de login
    validateLicense() {
      // Appel du service web de license
      if (this.input.license !== '') {
        this.validatingLicense = true
        let formData = new FormData()
        formData.append('license', this.input.license)
        this.$http.post(this.$config.apiUrl + '/?service=license_id', formData).then(response => {
          this.processLicenseAuthResponse(response.data.result)
        }).catch(() => {
          this.displayMessage(this.$t('error_occured'), 'error')
          this.isValidLicense = false
        }).finally(() => this.validatingLicense = false)
      } else {
        this.displayMessage(this.$t('license.empty'), 'error')
      }
    },
    processLicenseAuthResponse(result) {
      if (result && result.success) {
        this.isValidLicense = true
        // on sauvegarde l'essentiel dans le localstorage
        localStorage.license = this.input.license
        localStorage.branchName = result.agency_name
        this.branchName = result.agency_name;
        localStorage.changeAndGold = result.changeAndGold
        // Le local storage ne sait enregistrer que des string
        localStorage.agencies = JSON.stringify(result.agencies)
        localStorage.currency = result.iso
      } else {
        this.isValidLicense = false
        this.displayMessage(this.$t('license.invalid'), 'error')
      }
    },
    // Demande confirmation à l'utilisateur pour vider le champ licence
    removeLicense() {
      this.isValidLicense = false
      this.input.username = ''
      this.input.password = ''
      this.$http.post(this.$config.apiUrl + '/?service=auth_disconnect')
      localStorage.clear();
    },
    // Déclenché au clic sur le bouton "login" afin d'authentifier l'utilisateur
    tryLogin() {
      if (this.input.username !== '' && this.input.password !== '') {
        this.validatingLogin = true
        // appel du service web d'authentification
        let formData = new FormData()
        formData.append('user', this.input.username)
        formData.append('password', this.input.password)
        this.$http.post(this.$config.apiUrl + '/?service=auth', formData).then(response => {
          if (response.data.result.success) {
            localStorage.username = this.input.username
            this.redirectAfterSuccessLogin()
          } else {
            this.displayMessage(this.$t('login.failedMessage'), 'error')
          }
          this.validatingLogin = false
        }).catch(() => {
          this.displayMessage(this.$t('error_occured'), 'error')
          this.isValidLicense = false
          this.input.username = ''
          this.input.password = ''
          this.trySavedOrGivenLicence()
        }).finally(() => {
          this.validatingLogin = false
        })
      } else {
        this.displayMessage(this.$t('login.empty'), 'error')
      }
    },
    /**
     * Affichage de messages
     */
    displayMessage(text, color) {
      this.snackbar.show = false
      // Pour s'assurer que le timeout fonctionne
      this.$nextTick(() => {
        this.snackbar.show = true
        this.snackbar.text = text
        this.snackbar.color = color
      })
    },
    /**
     * Evenement déclenché après scan d'un QRCode afin de charger la licence
     * @param scannedUrl
     */
    onDecode(scannedUrl) {
      this.showQRScanner = false
      let url = new URL(scannedUrl)
      this.input.license = url.searchParams.get('license')
      this.validateLicense()
    },
    /**
     * Gestion des erreurs du scanner de QRCode
     * @param promise
     * @returns {Promise<void>}
     */
    async onInit(promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.displayMessage(this.$t('scanner.not_allowed_error'), 'error')
        } else if (error.name === 'NotFoundError') {
          this.displayMessage(this.$t('scanner.not_found_error'), 'error')
        } else if (error.name === 'NotSupportedError') {
          this.displayMessage(this.$t('scanner.not_supported_error'), 'error')
        } else if (error.name === 'NotReadableError') {
          this.displayMessage(this.$t('scanner.not_readable_error'), 'error')
        } else if (error.name === 'OverconstrainedError') {
          this.displayMessage(this.$t('scanner.overconstrained_error'), 'error')
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.displayMessage(this.$t('scanner.stream_api_not_supported_error'), 'error')
        }
        this.showQRScanner = false
      }
    },
    /**
     * Après le login on va sur la page souhaitée ou à défaut le billing monthly
     */
    redirectAfterSuccessLogin() {
      let go = this.$router.currentRoute.query.go
      if (go) {
        this.$router.replace({path: go})
      } else {
        this.$router.replace({path: '/billing/monthly'})
      }
    }
  }
}
</script>

<style scoped>
.login-form {
  max-width: 480px;
}
</style>
