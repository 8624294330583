import Vue from 'vue'
import Router from 'vue-router'
import OverviewLogin from './views/OverviewLoginView.vue'
import Navigation from './components/NavigationFrame.vue'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'login',
      component: OverviewLogin
    },
    {
      path: '/home',
      name: 'home',
      meta: {
        requiresAuth: true
      },
      props: {
        title: 'home'
      },
      // Lazy loading ;)
      components: {
        default: () => import('./views/HomeView.vue'),
        navigation: Navigation
      }
    },
    {
      path: '/billing/annually',
      name: 'billing.annually',
      meta: {
        requiresAuth: true
      },
      components: {
        default: () => import('./views/BillingAnnuallyView.vue'),
        navigation: Navigation
      }
    },
    {
      path: '/billing/monthly',
      name: 'billing.monthly',
      meta: {
        requiresAuth: true
      },
      components: {
        default: () => import('./views/BillingMonthlyView.vue'),
        navigation: Navigation
      }
    },
    {
      path: '/billing/daily',
      name: 'billing.daily',
      meta: {
        requiresAuth: true
      },
      components: {
        default: () => import('./views/BillingDailyView.vue'),
        navigation: Navigation
      }
    },
    {
      path: '/stocks',
      name: 'stocks',
      props: true,
      meta: {
        requiresAuth: true
      },
      components: {
        default: () => import('./views/StocksView.vue'),
        navigation: Navigation
      }
    },
    {
      path: '/stocks/currencies',
      name: 'stocksCurrencyDetail',
      props: true,
      meta: {
        requiresAuth: true
      },
      components: {
        default: () => import('./views/StocksCurrencyDetailView.vue'),
        navigation: Navigation
      }
    },
    {
      path: '/stocks/products',
      name: 'stocksProductDetail',
      props: true,
      meta: {
        requiresAuth: true
      },
      components: {
        default: () => import('./views/StocksProductDetailView.vue'),
        navigation: Navigation
      }
    },
    {
      path: '/treasury',
      name: 'treasury',
      meta: {
        requiresAuth: true
      },
      components: {
        default: () => import('./views/TreasuryView.vue'),
        navigation: Navigation
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // TODO Contrôler autrement qu'un appel service
    next()
  } else {
    next()
  }
})

export default router
