<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <nav>
    <v-app-bar app>
      <v-app-bar-nav-icon @click.stop="toggleDrawer()"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <span class="yd-toolbar-logo">Yoda<span class="yd-toolbar-logo-blue">Cloud</span></span>
      </v-toolbar-title>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" id="drawer" app>

      <v-list-item style="text-align: center">
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            <v-list-item-title>{{ this.username }}</v-list-item-title>
          </v-list-item-title>
          <v-list-item-subtitle>
            <v-btn text x-small @click="logout()" :title="$t('nav.logout')">
              Se déconnecter
            </v-btn>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list nav>

        <v-list-group prepend-icon="description">
          <template v-slot:activator>
            <v-list-item-title>{{ $t('billing.title') }}</v-list-item-title>
          </template>
          <v-list-item @click="$router.push('/billing/annually')">
            <v-list-item-title>{{ $t('billing.annually') }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$router.push('/billing/monthly')">
            <v-list-item-title>{{ $t('billing.monthly') }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$router.push('/billing/daily')">
            <v-list-item-title>{{ $t('billing.daily') }}</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-item @click="$router.push('/stocks')">
          <v-list-item-icon>
            <v-icon>archive</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t('stocks.title') }}</v-list-item-title>
        </v-list-item>

        <v-list-item @click="$router.push('/treasury')">
          <v-list-item-icon>
            <v-icon>attach_money</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t('treasury.title') }}</v-list-item-title>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
export default {
  name: 'NavigationFrame',
  data() {
    return {
      drawer: false,
      username: localStorage.username
    }
  },
  methods: {
    logout() {
      this.$http.post(this.$config.apiUrl + '/?service=auth_disconnect', {cache: false})
      this.$router.replace({name: 'login'})
    },
    annually() {
      this.$router.replace({path: '/billing/annually'})
    },
    monthly() {
      this.$router.replace({path: '/billing/monthly'})
    },
    daily() {
      this.$router.replace({path: '/billing/daily'})
    },
    stocks() {
      this.$router.replace({name: 'stocks'})
    },
    treasury() {
      this.$router.replace({name: 'treasury'})
    },
    /**
     * On décale les filtres à l'affichage du drawer
     */
    toggleDrawer() {
      this.drawer = !this.drawer
      // On vérifie si le drawer est en mode mobile, dans ce cas il se place par dessus et on a rien à faire
      if (!document.getElementById('drawer').classList.contains('v-navigation-drawer--is-mobile')) {
        let bodyClassList = document.body.classList
        if (this.drawer) {
          bodyClassList.add('yd-drawer-open')
        } else {
          bodyClassList.remove('yd-drawer-open')
        }
      }
    }
  },
  props: {
    title: String
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
  color: black;
}

.yd-toolbar-logo-blue {
  color: #47ade1;
}

.yd-toolbar-logo-beta {
  color: #c74e4d;
  font-weight: lighter;
  font-variant: all-petite-caps;
  font-size: smaller;
  vertical-align: super;
  margin: .2em;
}

</style>
