<template>
  <v-app>
    <router-view name="navigation"></router-view>
    <router-view ></router-view>
  </v-app>
</template>

<script>
export default {
  name: 'app',
}
</script>

<style>
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    //text-align: center;
    color: #2c3e50;
  }

  .align-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .v-list {
    padding: 0;
  }

  /**
  Pour décaler le contenu par rapport aux filtres quand il y a 3 filtres
   */
  .yd-content-3 {
    margin-top: 11em;
  }

  /**
  Pour décaler le contenu par rapport aux filtres quand il y a 1 filtre
   */
  .yd-content-1 {
    margin-top: 4em;
  }

  /**
  Pour décaler le contenu par rapport aux filtres quand il y a 2 filtres
   */
  .yd-content-2 {
    margin-top: 7.5em;
  }

  /**
  Pour fixer les filtres
   */
  .yd-filter {
    position: fixed;
    width: 100%;
    z-index: 2;
  }

  /**
  Pour décaler les filtres selon la taille du menu
   */
  .yd-drawer-open .yd-filter {
    right: 0;
    padding-left: 300px;
  }

  /**
  Pour colorer le filtre d'agence en cas d'agence sélectionnée
   */
  .selected-agency {
    color: white !important;
    background-color: dodgerblue;
    border-radius: 13px;
    padding: 1px;
  }

  .y-custom-app-bar-addon {
    position: sticky !important;
    top: 56px;
    z-index: 2;
  }

  @media (min-width: 960px) {
    .y-custom-app-bar-addon {
      top: 64px;
    }
  }

</style>
