
import Axios from "axios";
import {cacheAdapterEnhancer} from "axios-extensions";
import {LRUCache} from "lru-cache";

const axiosInstance = Axios.create({
  adapter: cacheAdapterEnhancer(Axios.defaults.adapter, { defaultCache: new LRUCache({ maxAge: 60 * 60 * 24 * 31 * 1000, max: 2048 }) }),
  withCredentials: true
})

// Sur une erreur 403 on retourne à l'accueil
axiosInstance.interceptors.response.use((response) => {
  return response
}, function (error) {
  if (!error.response || error.response.status === 403) {
    window.location.href = '/#?go=' + window.location.hash.substring(1)
  }
  return Promise.reject(error)
})

export default axiosInstance;
