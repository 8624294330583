import Vue from 'vue'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import Config from './config'
import VueCookie from 'vue-cookie'
import FlagIcon from 'vue-flag-icon'
import VueMeta from 'vue-meta'
import axios from './plugins/axios'

Vue.config.productionTip = false

Vue.prototype.$http = axios
Vue.prototype.$config = Config
Vue.prototype.$cookie = VueCookie

Vue.use(FlagIcon)
Vue.use(VueMeta)

new Vue({
  vuetify,
  router,
  i18n,
  render: h => h(App),
  metaInfo: () => ({
    meta: [
      { name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' },
      { name: 'apple-mobile-web-app-capable', content: 'yes' },
      { name: 'mobile-web-app-capable', content: 'yes' }
    ]
  })
}).$mount('#app')
